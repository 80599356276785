import React from 'react';
import Card from './Card';
import Sdata from './Sdata';

const Blogs = () =>{
    return(    
        <>
            {/* Main Conten */}
            <div className="container mt-5 mb-5">
            <div className="row gy-4">
                {
                     Sdata.map((val,ind) => {
                        return <Card key={ind} imgsrc={val.imgsrc} title={val.title} blogtext={val.blogtext} code={val.code}/>
                        })
                }
            </div>
	        </div>                            
        </>
    ); 
}
export default Blogs;