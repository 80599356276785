import React from 'react';

const Qtcard = () =>
{
    return (
        <>
            {/* <!-- Start QT --> */}
            <div className="qt-box qt-background">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 ml-auto mr-auto text-left">
                        <p className="lead ">
                            "Without big data analytics, companies are blind and deaf, wandering out onto the web like deer on a Freeway."
                        </p>
                        <span className="lead">Elliot Alderson</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Qtcard;