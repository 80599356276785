import React from 'react';
import $ from "jquery";

const ReplyCard = () => {
		var url = document.location.href,
			params = url.split('?')[1].split('#')[0].split('&'),
			data = {}, tmp;
		for (var i = 0, l = params.length; i < l; i++) {
			tmp = params[i].split('=');
			data[tmp[0]] = tmp[1];
			window.code=data.code;
		}
	$.ajax({
		type:"POST",
		url:"https://rrcsolutions.in/comments.php",
		dataType: "html",
		data:"code="+window.code,
		async: false,
		success:function(data){
			window.a=data;
		}
	  });

    return(
        <>
			<div className="card mb-3 bg-transparent border-0">
				<div className="row">
                    <div className="col-md-8">
						<div className="card-body  bg-transparent" id="Comments" dangerouslySetInnerHTML={{__html: window.a}}>
						</div>
					</div>
				</div>
            </div>
        </>
    );

}

export default ReplyCard;