import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
// import { NavLink } from 'react-router-dom';
const Footer = () => {
    return(<>
        {/* -- ======= Footer ======= -- */}
            <footer id="footer">
                <div className="footer-top">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-info">
                        <h3>RRC Solutions</h3>
                        <p>
                            <strong>Phone:</strong> +91 8890-50-8659<br/>
                            <strong>Email:</strong> mailus@rrcsolutions.in<br/><br/>  
                        </p>
                        <div className="social-links mt-3">
                            <a href="https://www.instagram.com/rrc.solutions/" target="_blank"><i className="bx bxl-instagram"></i></a>
                            <a href="https://www.linkedin.com/company/rectified-ramified-customer-solutions" target="_blank" ><i className="bx bxl-linkedin"></i></a>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><i className="bx bx-chevron-right"></i> <a className="nlink" href="https://rrcsolutions.in/index">Home</a></li>
                        <li><i className="bx bx-chevron-right "></i> <a className="nlink" href="https://rrcsolutions.in/services">Services</a></li>
						<li><i className="bx bx-chevron-right "></i> <a className="nlink" href="https://rrcsolutions.in/Tools">Tools</a></li>
						<li><i className="bx bx-chevron-right "></i> <a className="nlink" href="#">Blogs</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="https://rrcsolutions.in/aboutus">About us</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="https://rrcsolutions.in/contactus">Contact Us</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a href="https://rrcsolutions.in/privacy">Privacy policy</a></li>
                    </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Our Team</h4>
                        <ul>
                        <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.linkedin.com/in/rahul-sharma-111874198">Rahul Sharma</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.linkedin.com/in/rsharma5500">Rahul Sharma</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.linkedin.com/in/prateek-shukla-a04071199/">Prateek Shukla</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.linkedin.com/in/dt03/">Divyansh Tiwari</a></li>
                        <li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.linkedin.com/in/prasfur-tiwari-b93818178">Prasfur Tiwari</a></li>
						<li><i className="bx bx-chevron-right"></i> <a target="_blank" href="https://www.linkedin.com/in/sonamk605/">Sonam Kumar</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 footer-newsletter">
                        <h4>Our Newsletter</h4>
                        <p>Register Here for our Newsletter</p>
                        <form action="" method="post">
                            <input type="email" name="email" required/><input type="submit" name="submit" value="Subscribe"/>
                        </form>

                    </div>

                    </div>
                </div>
                </div>

                <div className="container">
                <div className="copyright">
                    All Rights Reserved. &copy; 2020 <a href="https://rrcsolutions.in/index"><strong><span>RRC</span></strong></a>
                </div>
                </div>
            </footer>
            {/* <!-- End Footer --> */}
        </>
    );
}

export default Footer;