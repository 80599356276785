import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () =>{

            return(
                <>
                      <a id="scroll" href="#scroll_to"></a>
                    {/* <!-- ======= Top Bar ======= --> */}
                        <div id="topbar" className="d-flex align-items-center">
                            <div className="container d-flex">
                            <div className="contact-info mr-auto">
                                <i className="fa fa-phone"></i> +91 8890-50-8659 <span className="d-none d-lg-inline-block"><i className="fa fa-envelope ml-3"></i>  mailus@rrcsolutions.in</span>
                            </div>
                            </div>
                        </div>
                        {/* <!-- ======= Header ======= --> */}
                        <header id="header" className="fixed-top">
                            <div className="container d-flex align-items-center">
                            <a href="https://rrcsolutions.in/index" className="logo mr-auto"><img src="https://rrcsolutions.in/assets/img/logoRRC.png" alt="logo" className="img-fluid"/><span className="logodef">RRC Solutions</span></a>
                            <nav className="nav-menu d-none d-lg-block">
                                <ul id="nav">
                                    <li id="index"><a  href="https://rrcsolutions.in/index" style={{ textDecoration: 'none !important' }}>Home</a></li>
                                    <li id="services"><a href="https://rrcsolutions.in/services" style={{ textDecoration: 'none !important' }} >Services</a></li>
									<li id="tools"><a href="https://rrcsolutions.in/tools" style={{ textDecoration: 'none !important' }} >Tools</a></li>
                                    <li id="blogs"><NavLink to="/Blogs" style={{ textDecoration: 'none !important' }}>Blogs</NavLink></li>
                                    <li id="aboutus"><a href="https://rrcsolutions.in/aboutus" style={{ textDecoration: 'none !important' }}>About Us</a></li>
                                    <li id="contactus"><a href="https://rrcsolutions.in/contactus" style={{ textDecoration: 'none !important' }}>Contact Us</a></li>
                                    <li className="login text-center"><a href="https://rrcsolutions.in/Login" style={{ textDecoration: 'none !important' }}>Login/Signup</a></li>
                                </ul>
                            </nav>
                            </div>
                        </header>
                        {/* <!-- End Header --> */}
                </>
            );
}

export default Navbar;