import React from 'react';
import web from '../src/images/blog.png';
import twitter from '../src/images/twitter.png';
import fb from '../src/images/fb.png';
import whatsapp from '../src/images/whatsapp.jpg';
import ReplyCard from './ReplyCard';
import CommentBox from './CommentBox';
import Sdata from './Sdata';
import Blog from './Blog';


const Blogpage = (props) => {
	console.log(props);
        return (
            <>
                <div className="container mt-5 mb-5">
                    <div className="row">
                    <div className=" col-12 mx-auto ">
						{
							 Sdata.map((val,ind) => {
								return <Blog key={ind} imgsrc={val.imgsrc} title={val.title} content={val.content} code={val.code} datetime={val.datetime}/>
								})
						}
                        <div className=" col-12 mx-auto mt-2">
                            <h5 >Read Comments <hr/></h5>
                            <ReplyCard />
                        </div>
                        <div className="col-3 mx-auto mb-3 float-left">
                            <a href="#CommentBox" data-bs-toggle="collapse" className="btn text-light rounded-pill pr-5 pl-5" role="button" aria-expanded="false" aria-controls="collapseExample">
                                Post Comment
                            </a>
                        </div>
                        <div className="col-10 mt-2 mx-auto float-left collapse" id="CommentBox">
                            <CommentBox />
                        </div>
  
                    </div>
                    </div> 
                </div>
            </>
        );
};

export default Blogpage;