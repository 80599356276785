import React from 'react';
import { Facebook, Twitter, Whatsapp} from 'react-sharingbuttons';
import 'react-sharingbuttons/dist/main.css';
import {
  FacebookShareButton,FacebookIcon,
  GooglePlusShareButton,GooglePlusIcon,
  LinkedinShareButton,LinkedinIcon,
  TwitterShareButton,TwitterIcon,
  TelegramShareButton,TelegramIcon,
  WhatsappShareButton,WhatsappIcon,
  EmailShareButton,EmailIcon
} from 'react-share';

const SocialMedia = () => {
    const url = 'https://rrcsolutions.in/index.php';
    return (
      <>
        <FacebookShareButton url={url} >
          <FacebookIcon size={32} round={true} className="mr-1"/>
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon size={32} round={true} className="mx-1" />
        </TwitterShareButton>
        <EmailShareButton nurl={url}>
          <EmailIcon size={32} round={true} className="mx-1" />
        </EmailShareButton>
        <WhatsappShareButton url={url} >
          <WhatsappIcon size={32} round={true} className="mx-1" />
        </WhatsappShareButton>
        <TelegramShareButton url={url} >
          <TelegramIcon size={32} round={true} className="mx-1"/>
        </TelegramShareButton>
      </>
    )
  }
  
  export default SocialMedia;