import React from 'react';
import { NavLink } from 'react-router-dom';


const Card = (props) => {
	var url = "/Blogpage?code="+props.code;
	console.log(props);
    return(    
            <>
               <div className="col-sm-12 col-md-4 col-lg-3 mx-auto">
                    <div className="card blogcard">
                        <img src={props.imgsrc} className="card-img-top p-3" alt="..."/>
                        <div className="card-body  ">
                            <h5 className="card-title text-dark">{props.title}</h5>
                            <p className="card-text text-secondary">{props.blogtext}<hr/></p>
                            <NavLink to={url} className="btn rounded-pill">Read More</NavLink>
                        </div>
                    </div>
                </div>
            </>
        ); 
}
export default Card;
