import React from 'react';
import $ from "jquery";

const CommentBox = () =>{
		var url = document.location.href,
			params = url.split('?')[1].split('#')[0].split('&'),
			data = {}, tmp;
		for (var i = 0, l = params.length; i < l; i++) {
			tmp = params[i].split('=');
			data[tmp[0]] = tmp[1];
			window.code=data.code;
		}
    return(<>
                <div className="mb-2">
                    <input type="text" className="form-control bg-transparent box-color" id="name" placeholder="Name (required)"/>
                </div>
                <div className="mb-2">
                    <input type="email" className="form-control bg-transparent box-color" id="email" placeholder="Mail (will not be publised) (required)"/>
                </div>
                <div className="mb-2">
                    <textarea className="form-control bg-transparent box-color" id="comment" rows="1" placeholder="Comment"></textarea>
                </div>
				<em> Comments aren't yet enabled, please check back later </em>
                <div className="mx-auto mb-2 ">
                    <button className="btn rounded-pill box-color " style={{color: '#ffff', background:'#28999f', border:'0px solid'}}>Post Comment</button>
                </div>
        </>
    );
};

export default CommentBox;