import React from 'react';

const AllPagecard = () =>{
    return(
        <>
            {/* Start All Pages */}
            <div className="all-page-title page-breadcrumb">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-lg-12">
                        <h1 id="scroll_to">Blog</h1>
                        <p id="p1">RRC Solutions and Team</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* End All Pages */}
        </>
    );
};
export default AllPagecard;