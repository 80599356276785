import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import {Switch, Route, Redirect} from 'react-router-dom';
import Blogs from './Blogs';
import AllPagecard from './AllPagecard';
import Qtcard from './Qtcard';
import Navbar from './Navbar';
import Footer from './Footer';
import Blogpage from './Blogpage';
import CommentBox from './CommentBox';
import "./index.css";


const App = () =>{
    return(    
        <>
            <Navbar />
            <AllPagecard/>
            <Switch>
                <Route exact path="/Blogs" component={Blogs}/>
                <Route exact path="/Blogpage" component={Blogpage}/>
                <Route exact path="/Blogpage/CommentBox" component={CommentBox}/>
                <Route component={Blogs}/>
            </Switch>
            <Qtcard/>
            <Footer /> 
        </>
    ); 
}
export default App;