import React from 'react';
import SocialMedia from './SocialMedia';

const Blog = (props) => {
	try{
		//Fetching Blog code from url
		var url = document.location.href,
			params = url.split('?')[1].split('#')[0].split('&'),
			data = {}, tmp;
		for (var i = 0, l = params.length; i < l; i++) {
			tmp = params[i].split('=');
			data[tmp[0]] = tmp[1];
		}
		/*If The code in the url matches the blog code return blog Content*/
		if(props.code==data.code){
			return(
				<>
					<div className="card blogpage bg-transparent" id="blog_div">   
						<div className="card-body bg-transparent">
							<h3 className="card-title mb-0 pb-0">{props.title}</h3>
							<p class="card-text"><small className="text-muted mt-0 mb-3 pb-3 ">{props.datetime}</small></p>  
							<img src={props.imgsrc} className="card-img-top img-fluid blogpagecard " alt="..."/>								
							<p className="card-text mb-0 mt-0" id="content" dangerouslySetInnerHTML={{__html: props.content}}></p>
							<div className="mt-2 float-left pr-2 pf-2 ">
								<SocialMedia />
							</div>
						</div>
					</div>
				</>
			); 
		}
		/*Else return nothing*/
		else{
			return(<> </>);
		}
	}
	catch(e){
		window.top.location.href="https://blogs.rrcsolutions.in";
	}
}
export default Blog;